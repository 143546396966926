<template>
  <CmsLayout class="app-redirect-page">
    <main>
      <div v-if="!isLoaded" class="loader">
        <LoaderSpinner />
        <div v-html="getTranslation('wait')" />
      </div>
      <ButtonDefault v-else size="medium" to="/">
        <span v-html="getTranslation('back_to_main')" />
      </ButtonDefault>
    </main>
  </CmsLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { SequoiaComponent } from '../mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { GTM_EVENT_TIMEOUT } from 'src/constants';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import {
  BROWSERS,
  getBrowserName,
  getDeviceFlags,
  getOsFlags,
  getOsName,
  isAndroidWebview,
} from 'src/utils/platform-detector';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import logger from 'src/utils/logger';
import { localStore } from 'src/utils/storage';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';

const log = logger('app-redirect-page');

@Component({
  components: {
    ButtonDefault,
    CmsLayout,
    LoaderSpinner,
    ModalSequoia,
  },
})
export default class AppRedirectPage extends SequoiaComponent {
  isLoaded = false;
  isActive = false;
  isDone = false;
  deviceNotFound = false;
  blurHappened = false;

  waitForAppTime = 2000;

  @Watch('isLoaded')
  onIsLoadingChange(val: boolean) {
    actions.common[val ? 'showFooter' : 'hideFooter'](this.$store);
  }

  // scheme for opening apps
  // e.g. lfstrm://, uztelecomtv://
  get scheme() {
    return this.$store.siteConfig?.appStores.scheme;
  }

  get storeLink() {
    return this.$store.siteConfig?.appStores.links[getOsName()];
  }
  get defaultStoreLink() {
    return this.$store.siteConfig?.appStores.links.default;
  }
  get redirectLink() {
    return this.storeLink || this.defaultStoreLink;
  }
  get isMobile() {
    return getDeviceFlags().isMobile;
  }
  get isTablet() {
    return getDeviceFlags().isTablet;
  }
  get isAndroidDeviceWithoutGooglePlay() {
    return getDeviceFlags().isAndroidWithoutGooglePlay;
  }
  get isMobileOrTablet() {
    return this.isMobile || this.isTablet;
  }
  get isIos() {
    return getOsFlags().isIos;
  }
  get isAndroid() {
    return getDeviceFlags().isAndroid;
  }
  get isSafari() {
    return getBrowserName() === BROWSERS.safari;
  }
  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }
  get showModal() {
    return !this.isModalOpen && !this.deviceNotFound;
  }

  mounted() {
    actions.common.showFooter(this.$store);

    localStore.set('showSmartAppBanner', false);

    if (!this.isMobileOrTablet) {
      const isOneLogginedCookieDate = new Date(
        new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000
      ).toUTCString();

      document.cookie = 'is_one_loggined=1; path=/; expires=' + isOneLogginedCookieDate + ';';

      log.warning('This is not a mobile or tablet device!');

      if (this.defaultStoreLink) {
        window.location.href = this.defaultStoreLink;
      } else {
        this.$router.push('/');
      }
      return;
    } else if (this.isSafari) {
      // hack for iOS Safari to redirect to App Store
      // in case the app was not installed
      setTimeout(() => {
        this.onAppNotFound();
      }, this.waitForAppTime);
    }

    // magic for native app start on other platforms
    const waitForAppStartTimeout = setTimeout(this.onAppNotFound, this.waitForAppTime);

    window.onblur = () => {
      log.info('BLUR happened – there is a chance that application exists...');
      this.blurHappened = true;
      clearTimeout(parseInt(waitForAppStartTimeout.toString()));
      // trigger start in case application exists
      this.onAppStart();
    };

    window.onfocus = () => {
      if (this.blurHappened) {
        log.info('FOCUS has just returned back to site');
        window.onfocus = window.onblur = null;
        this.onAppDone();
      }
    };

    if (this.scheme && !isAndroidWebview()) {
      document.location = this.scheme;
      // window.open(this.scheme, '_blank');
    }
  }
  destroyed() {
    actions.common.hideFooter(this.$store);
  }
  onAppNotFound() {
    log.info('onAppNotFound – redirecting to', this.redirectLink);
    this.gaEvent({ category: 'app_redirect', action: `Приложение не найдено` });
    this.redirectToStore();
    this.isLoaded = true;
  }

  onAppStart() {
    log.info('onAppStart');
    this.isLoaded = true;
    this.isActive = true;
    this.gaEvent({ category: 'app_redirect', action: 'Приложение запущено' });
  }

  onAppDone() {
    log.info('onAppDone');
    this.isLoaded = true;
    this.isActive = false;
    this.isDone = true;
    this.gaEvent({
      category: 'app_redirect',
      action: 'Приложение завершено - редирект на главную',
    });
    if (this.$route.path !== '/') {
      this.$router.push('/');
    }
  }

  redirectToStore() {
    if (
      this.isAndroidDeviceWithoutGooglePlay ||
      (!this.isIos && !this.isAndroid && this.isMobileOrTablet)
    ) {
      this.gaEvent({ category: 'app_redirect', action: 'Устройство не распознано' });
      log.warning('Device was not detected');
      this.$store.common.isModalWithAppsOpen = true;
      this.$router.push('/');
      return;
    }

    if (!this.redirectLink) {
      log.warning('Redirect link was not provided');
      return;
    }

    this.gaEvent({ category: 'app_redirect', action: getOsName() });
    setTimeout(() => (window.location = this.redirectLink), GTM_EVENT_TIMEOUT);
  }
}
</script>

<style lang="scss" scoped>
.app-redirect-page {
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 64px;

    .loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .loader-spinner {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
